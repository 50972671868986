<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <h2 class="mb-25 font-weight-bolder title">
          {{ $t('CREATING_NEW_COMPANY') }}
        </h2>
        <hr>
      </b-col>
    </b-row>
    <b-alert
      variant="danger"
      :show="errorShow"
    >
      <div class="alert-body font-small-2">
        <p class="mr-50">
          {{ $t(errorInsert) }}
        </p>
      </div>
    </b-alert>
    <!-- form -->
    <validation-observer ref="newCompanyForm">
      <b-form
        class="mt-2"
      >
        <b-row>
          <b-col sm="8">
            <b-form-group
              :label="$t('COMPANY_NAME')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('COMPANY_NAME')"
                rules="required"
              >
                <b-form-input
                  v-model="company.name"
                  :state="errors.length > 0 ? false:null"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              :label="$t('COMPANY_NAVISION_CODE')"
              label-for="navisionClientCode"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('COMPANY_NAVISION_CODE')"
                rules="required"
              >
                <b-form-input
                  v-model="company.navisionClientCode"
                  :state="errors.length > 0 ? false:null"
                  name="navisionClientCode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('ADRESS')"
              label-for="adress"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('ADRESS')"
                rules="required"
              >
                <b-form-input
                  v-model="company.adress"
                  :state="errors.length > 0 ? false:null"
                  name="adress"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('ADRESS_2')"
              label-for="adress2"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('ADRESS_2')"
              >
                <b-form-input
                  v-model="company.adress2"
                  :state="errors.length > 0 ? false:null"
                  name="adress2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <b-form-group
              :label="$t('POSTAL_CODE')"
              label-for="postalCode"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('POSTAL_CODE')"
                rules="required"
              >
                <b-form-input
                  v-model="company.postalCode"
                  :state="errors.length > 0 ? false:null"
                  name="postalCode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
              :label="$t('CITY')"
              label-for="city"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('CITY')"
                rules="required"
              >
                <b-form-input
                  v-model="company.city"
                  :state="errors.length > 0 ? false:null"
                  name="city"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group
              :label="$t('PAYS')"
              label-for="pays"
            >
              <v-select
                v-model="company.pays"
                name="pays"
                :options="pays"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('PHONE_NUMBER')"
                rules="required|min:10"
              >
                <label for="libelle">{{ $t('PHONE_NUMBER') }}: </label>
                <cleave
                  id="phone"
                  v-model="company.phone"
                  type="text"
                  class="form-control"
                  :raw="false"
                  :options="champsformater.numero"
                  maxlength="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('COMPANY_EMAIL_CONTACT')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('COMPANY_EMAIL_CONTACT')"
                rules="required|email"
              >
                <b-form-input
                  v-model="company.emailContact"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col />
          <b-col
            cols="4"
          >
            <b-button
              v-if="loading"
              variant="primary"
              type="button"
              block
              disabled
            >
              <b-spinner small /> Loading ...
            </b-button>
            <b-button
              v-else
              variant="primary"
              type="submit"
              block
              @click.prevent="insertCompany"
            >
              {{ $t("NEW_COMPANY_BUTTON") }}
            </b-button>
          </b-col>
          <b-col />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BAlert, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.fr'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    Cleave,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      loading: false,
      errorShow: false,
      errorInsert: '',
      champsformater: {
        numero: {
          phone: true,
          delimiter: '',
          phoneRegionCode: 'FR',
          blocks: [10],
        },
      },
      pays: [],
      company: {
        name: '',
        navisionClientCode: '',
        phone: '',
        adress: '',
        adress2: '',
        postalCode: '',
        city: '',
        emailContact: '',
        pays: '',
      },
    }
  },
  mounted() {
    this.$store.dispatch('companies/fetchAllPays').then(pays => {
      this.pays = pays
      const france = pays.filter(el => el.label === 'France')
      // eslint-disable-next-line prefer-destructuring
      this.company.pays = france[0]
    })
  },
  methods: {
    insertCompany() {
      const that = this
      this.loading = true
      this.$refs.newCompanyForm.validate().then(success => {
        if (success) {
          const newCompany = {
            name: this.company.name,
            navisionClientCode: this.company.navisionClientCode,
            phone: this.company.phone,
            adress: this.company.adress,
            adress2: this.company.adress2,
            postalCode: this.company.postalCode,
            city: this.company.city,
            emailContact: this.company.emailContact,
            pays: this.company.pays.uri,
          }
          that.$store.dispatch('companies/insert', newCompany)
            .then(() => {
              that.loading = false
              that.success(newCompany)
            })
            .catch(e => {
              if (e.response && e.response.data) {
                that.errorInsert = that.$i18n.t(e.response.data)
              } else {
                that.errorInsert = that.$i18n.t(e)
              }
              that.errorShow = true
              that.loading = false
            })
        } else {
          that.loading = false
        }
      })
    },
    success(company) {
      this.$swal({
        title: this.$i18n.t('COMPANY_CREATED'),
        html:
          `<strong>${this.$t('COMPANY_CREATED_POPUP')} ${company.navisionClientCode} - ${company.name} ${this.$t('COMPANY_CREATED_POPUP2')}</strong>.`,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(isConfirm => {
        if (isConfirm) {
          this.$router.push({ path: '/admin/company/' })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
