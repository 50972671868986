var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h2',{staticClass:"mb-25 font-weight-bolder title"},[_vm._v(" "+_vm._s(_vm.$t('CREATING_NEW_COMPANY'))+" ")]),_c('hr')])],1),_c('b-alert',{attrs:{"variant":"danger","show":_vm.errorShow}},[_c('div',{staticClass:"alert-body font-small-2"},[_c('p',{staticClass:"mr-50"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorInsert))+" ")])])]),_c('validation-observer',{ref:"newCompanyForm"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"sm":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY_NAME'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_NAME'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"name"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY_NAVISION_CODE'),"label-for":"navisionClientCode"}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_NAVISION_CODE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"navisionClientCode"},model:{value:(_vm.company.navisionClientCode),callback:function ($$v) {_vm.$set(_vm.company, "navisionClientCode", $$v)},expression:"company.navisionClientCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ADRESS'),"label-for":"adress"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ADRESS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"adress"},model:{value:(_vm.company.adress),callback:function ($$v) {_vm.$set(_vm.company, "adress", $$v)},expression:"company.adress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ADRESS_2'),"label-for":"adress2"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ADRESS_2')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"adress2"},model:{value:(_vm.company.adress2),callback:function ($$v) {_vm.$set(_vm.company, "adress2", $$v)},expression:"company.adress2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('POSTAL_CODE'),"label-for":"postalCode"}},[_c('validation-provider',{attrs:{"name":_vm.$t('POSTAL_CODE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"postalCode"},model:{value:(_vm.company.postalCode),callback:function ($$v) {_vm.$set(_vm.company, "postalCode", $$v)},expression:"company.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('CITY'),"label-for":"city"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CITY'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"city"},model:{value:(_vm.company.city),callback:function ($$v) {_vm.$set(_vm.company, "city", $$v)},expression:"company.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('PAYS'),"label-for":"pays"}},[_c('v-select',{attrs:{"name":"pays","options":_vm.pays,"clearable":false},model:{value:(_vm.company.pays),callback:function ($$v) {_vm.$set(_vm.company, "pays", $$v)},expression:"company.pays"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('PHONE_NUMBER'),"rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"libelle"}},[_vm._v(_vm._s(_vm.$t('PHONE_NUMBER'))+": ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","type":"text","raw":false,"options":_vm.champsformater.numero,"maxlength":"10"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('COMPANY_EMAIL_CONTACT')}},[_c('validation-provider',{attrs:{"name":_vm.$t('COMPANY_EMAIL_CONTACT'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.company.emailContact),callback:function ($$v) {_vm.$set(_vm.company, "emailContact", $$v)},expression:"company.emailContact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col'),_c('b-col',{attrs:{"cols":"4"}},[(_vm.loading)?_c('b-button',{attrs:{"variant":"primary","type":"button","block":"","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Loading ... ")],1):_c('b-button',{attrs:{"variant":"primary","type":"submit","block":""},on:{"click":function($event){$event.preventDefault();return _vm.insertCompany.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("NEW_COMPANY_BUTTON"))+" ")])],1),_c('b-col')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }